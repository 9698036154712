







import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    components: {}
})
export default class TrooperzCarousel extends Vue {
  @Prop()
  size!: number

  @Prop()
  display!: number

  get imgList () {
    const imgName = ''
    return [80,415,395,132,223,92,109,16,17].map(item => {
      return `${imgName}${item}.png`
    })
  }
}

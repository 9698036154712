





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class Timeline extends Vue {
    items = [
      {
        title: "",
        body: [
             {text: "Community launch", done: true},
             {text: "First whitelist", done: true},
             {text: "Website launch", done: true},
             {text: "Making the Trooperz", done: true},
             {text: "Making the training mode", done: true},
             {text: "Listing on Objkt", done: true}
        ],
        year: 'Step 1 - Build',

        styleConfig: {
          background: "#2c2b3ec9",
          color: "white",
          dotColor: "rgba(185, 173, 184, 0.79)"
        }
      },
      {
        title: "",
        body:[
            {text: "19 May Presale", done: true},
            {text: "20 May Presale + reveal button available", done: true},
            {text: "21 May Public sale", done: true},
            {text: "Trooperz Gallery", done: true}
        ],
        year: 'Step 2 - Collect',

        styleConfig: {
          background: "#2c2b3ec9",
          color: "white",
          dotColor: "#00E6F6"
        }
      },
      {
        title: "",
        body:[
             {text: "Collect T-COINS in training mode", done: true},
             {text: "Trooperz Club Marketplace", done: true},
             {text: "Collectibles NFTs in the Trooperz universe", done: true}
        ],
        year: 'Step 3 - Play',

        styleConfig: {
          background: "#2c2b3ec9",
          color: "white",
          dotColor: "#00E6F6"
        }
      },
      {
        title: "",
        body:[
              {text: "Making Trooperz - the playable game", done: false, rocket: false},
              {text: "Collabs", done: false, rocket: true},
              {text: "Fun Contest on social networks", done: false, rocket: true},
              {text: "Expending our universe as far as possible", done: false, rocket: true},
        ],
        year: 'Step 4 - And More',

        styleConfig: {
          background: "#2c2b3ec9",
          color: "white",
          dotColor: "#00E6F6"
        }
      }
    ]

}

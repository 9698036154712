






import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import WalletMixin from '../mixins/WalletMixin'

@Component
export default class Thumbnail extends mixins(WalletMixin) {
  get text () {
      if (this.permission && this.permission.address) {
          return this.permission.address
      }
      return 'Sync'
  }

  async connect() {
      await this.requestPermissions()
  }
}

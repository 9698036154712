

















import { Component, Prop, Vue } from 'vue-property-decorator';
import simpleParallax from 'simple-parallax-js';
@Component
export default class Banner extends Vue {
    @Prop()
    windowWidth!: number

    created () {
        var image = document.getElementsByClassName('parallax-banner');
        new simpleParallax(image);
    }


    openDiscord () {
       window.open(this.discordUrl, '_blank');
    }

    openTwitter () {
       window.open(this.twitterUrl, '_blank');
    }
    get displayBtn () {
        return this.windowWidth > 600;
    }

    get twitterUrl () {
      return this.$config?.twitterLink
    }

    get discordUrl () {
      return this.$config?.discordLink
    }
}

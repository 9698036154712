























import { Component, Prop, Vue } from 'vue-property-decorator';
import TrooperzHoodImg from '@/components/TrooperzHoodImg.vue';
import TrooperzHoodText from '@/components/TrooperzHoodText.vue';
import TextAlone from '@/components/TextAlone.vue';
import { LIMIT_SCREEN_SMALL } from '../constants'
import { TitleContent } from '@/type';

@Component({
    components: {
        TrooperzHoodImg,
        TrooperzHoodText,
        TextAlone
    }
})
export default class TrooperzHood extends Vue {
  titleClass = 'cb-neon-blue'

  @Prop()
  content!: string

  @Prop()
  title!: string
  
  @Prop()
  imgPath!: string

  @Prop()
  subtitle!: string

  @Prop()
  index!: string

  @Prop()
  windowWidth!: number

  get contentObj (): TitleContent {
    return {
      title: this.title,
      content: this.content,
      img: this.imgPath,
      subtitle: this.subtitle
    }
  }

  get noIndexMode () {
    return this.windowWidth <= LIMIT_SCREEN_SMALL
  }
}

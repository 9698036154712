

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getIpfsPath } from '../utils/utilities'

@Component
export default class ContractInfos extends Vue {
    get crowdsaleAddress () {
        return this.$config ? this.$config.marketplaceContractAddress : ''
    }

    get nftTokenAddress () {
        return this.$config ? this.$config.tokenContractAddress : ''
    }

    getBetterCallDevLink (contract: string) {
        if (!this.$config) {
            return ''
        }
        return `https://better-call.dev/${this.$config.networkBetterCallDev}/${contract}/operations`
    }
}

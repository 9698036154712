




















import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class CrewCard extends Vue {
  text = 'SYNC'
  @Prop()
  name!: string 

  @Prop()
  role!: string 

  @Prop()
  twitter!: string 

  @Prop()
  img!: string 

    openTwitter () {
        window.open(this.twitterUrl, '_blank');
    }

    get twitterUrl () {
        return this.twitter.replace('@', 'https://twitter.com/')
    }
}

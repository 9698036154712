
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WhyTezos extends Vue {
    textWhyTezos = `
A transaction on Tezos consumes almost 2,000,000 times less energy than on Ethereum or Bitcoin. This makes it a clean blockchain, but also allows users to avoid breaking the bank in gas fees.

In addition, it is a scalable and truly decentralized blockchain that has proven to be future-proof.
    `

}

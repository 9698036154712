




















import { TitleContent } from '@/type';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextAlone extends Vue {
  @Prop()
  val!: TitleContent

  @Prop()
  index!: number


  @Prop()
  borderRadius!: boolean


  get left () {
    return !(this.index % 2)
  }
}

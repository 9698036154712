














import { Component, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import WalletMixin from '../mixins/WalletMixin'

@Component
export default class MintButton extends mixins(WalletMixin) {
    @Prop()
    disabled!: boolean

    @Prop()
    nbMint!: number

    @Prop()
    width!: number

    @Prop({default: 'Mint'})
    labelVerb!: string

    @Prop({default: 'soon !'})
    labelDisabled!: string

    emitMint () {
        this.$emit('mint', this.nbMint)
    }

    get labelBtn () {
        return this.disabled ? this.labelDisabled : `${this.labelVerb} ${this.nbMint}`
    }
}


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MintButton from '@/components/MintButton.vue';
import OperationLoadingDialog from '@/components/OperationLoadingDialog.vue'
import { LIMIT_SCREEN_SMALL } from '../constants'
import { mixins } from 'vue-class-component';
import WalletMixin from '../mixins/WalletMixin';
import { Contract } from '@taquito/taquito';
import { countdown } from '@/utils/utilities';

@Component({
    components: {
        MintButton,
        OperationLoadingDialog
    }
})
export default class MinterComponent extends mixins(WalletMixin) {
  activeDialog = false

  loading = false
  loadingMinter: any = null
  isLoadingMinter = false

  refNameLoadingMinter = 'loading-minter-container'

  success = false
  error = false

  opeHash?: string | null = null

  maxSupply: null | number = null
  nbMinted = null

  countdownPublicSale = ''
  countdownPreSale = ''

  contractStorage: any = null

  startSale: null | Date = null

  startPreSale: null | Date = null

  mintOption = [
    {
      amount: 1
    },
    {
      amount: 2
    },
    {
      amount: 3
    },
    {
      amount: 5
    },
    {
      amount: 8
    },
    {
      amount: 10
    }
  ]

  whiteListObj: any | null = null

  userIsWhitelisted = false
  restMint: number | null = null
  activePresale = false
  activeSale = false
  mounted () {
    this.getStorage()
    this.launchIntervalCountdownSale()
    this.launchIntervalCountdownPresale()
  } 


  closeDialog () {
    this.activeDialog = false
  }

  openLoadingMinter() {
      console.warn('open loading')
      this.loadingMinter = this.$vs.loading({
          target: this.$refs[this.refNameLoadingMinter],
          type: 'circles',
          opacity: '0',
          text: 'loading...'
      })
      this.isLoadingMinter = true
  }

  stopLoading() {
    if (this.loadingMinter) {
      (this.loadingMinter as any).close()
      this.isLoadingMinter = false
    }
  }

  changeLoadingText (text: string) {
    this.loadingMinter.changeText(text)
  }

  async getStorage () {
    if (this.$config) {
        this.openLoadingMinter()
        this.changeLoadingText('Load contract...')
        const contract = await this.$tezos.wallet.at(this.$config.marketplaceContractAddress)
        this.changeLoadingText('Load contract storage...')
        this.contractStorage = await contract.storage()
        this.maxSupply = this.contractStorage.max_supply - this.contractStorage.reserve_amount
        this.nbMinted = this.contractStorage.n_minted.toNumber()
        this.startSale = new Date(this.contractStorage.start_date_public)
        this.startPreSale = new Date(this.contractStorage.start_date_whitelist)

        const activeAccount = await this.$wallet.client.getActiveAccount()

        if (this.isOnPresale && activeAccount?.address) {
          console.warn('IS ON PRESALE')
          this.changeLoadingText('Get whitelist storage...')
          let wlBigMap = await this.contractStorage.whitelist
          let whitelist = await wlBigMap.get(activeAccount?.address)

          if (whitelist) {
            this.restMint = whitelist.toString()
            this.userIsWhitelisted = true
          } else {
            this.userIsWhitelisted = false
          }
        }
        this.stopLoading()
    }
  }

  get isOnPresale () {
    return this.startPreSale && this.startPreSale <= (new Date()) && !this.isOnPublicSale
  }

  get isOnPublicSale () {
    return this.startSale && this.startSale <= (new Date())
  }

  get isSaleStarted () {
    return this.isOnPresale || this.isOnPublicSale
  }

  get saleStatus () {
    if (this.nbMinted === this.maxSupply) {
      return 'Sold out'
    } else if (this.isOnPublicSale) {
      return 'Public sale'
    } else if (this.isOnPresale) {
      return 'Pre sale'
    }
    return 'Sale not started'
  }

  get isDisabledMint () {
    return !this.$config?.saleLaunched
  }

  get titleMinter () {
    return this.$config?.saleLaunched ? 'Trooperz Are Ready !' : 'Trooperz Are Coming !'
  }

  get price () {
    return this.$config ? this.$config.price : ''
  }

  get presalePrice () {
    return this.$config ? this.$config.pricePresale : ''
  }

  get getPriceSaleOrPreSale () {
    if (this.isOnPublicSale) {
      return this.price
    } else if (this.isOnPresale) {
      return this.presalePrice
    }
    return this.price
  }


  get walletAddress () {
    return this.$store.getters.getAddress
  } 

  set walletAddress (address: string | null) {
    this.$store.commit('setAddress', address)
  }

  async mint(nbMint: number) {
      if (!this.isSaleStarted) {
        console.warn('Sale not started!')
        return
      }

      if (!this.getPriceSaleOrPreSale) {
        console.warn('Can not find price!')
        return
      }

      let currentAddress = null
      this.success = false
      this.opeHash = null

      const price = this.getPriceSaleOrPreSale
      const activeAccount = await this.$wallet.client.getActiveAccount();
      const config = this.$config
      if (!config) {
          throw Error('No config !')
      }
      if (!activeAccount) {
          let permissions = await this.requestPermissions()
          currentAddress = permissions?.address
          this.walletAddress = currentAddress ? currentAddress : null
      } else {
          currentAddress = activeAccount.address
      }
      
      if (currentAddress) {
          let contract = null
          let op = null
          let hash = null
          let amount = price * nbMint

          try {
            contract = await this.$tezos.wallet.at(config.marketplaceContractAddress)
          } catch(er) {
            console.warn('Error when getting contract')
            this.handleMintError(er)
            return
          }

          try {
            op = await contract.methods.mint(nbMint).send({amount: amount})
          } catch(er) {
            console.warn('Error during mint')
            this.handleMintError(er)
            return
          }

          this.opeHash = op.opHash
          this.activeDialog = true
          this.loading = true
          try {
            hash = await op.confirmation(1)
          } catch(er) {
            console.warn('Error during confirmation. retry confirm...')
            hash = await op.confirmation(1)
          }

          this.loading = false
          this.success = true
          this.getStorage()
      }
  }

  launchIntervalCountdownSale () {
    if (!this.isOnPublicSale) {
      window.setInterval(() => {
        if (this.startSale) {
          this.countdownPublicSale = this.getCountdown(this.startSale)
          if (this.isOnPublicSale && !this.activeSale) {
              this.activeSale = true
          }
        }
      }, 1000)
    }
  }


  launchIntervalCountdownPresale () {
    if (!this.isOnPresale && !this.isOnPublicSale) {
        window.setInterval(() => {
            if (this.startPreSale) {
              this.countdownPreSale = this.getCountdown(this.startPreSale)
              if (this.isOnPresale && !this.activePresale) {
                  this.activePresale = true
              }
            }
        }, 1000)
    }
  }

  getCountdown (destination: Date) {
    let distance = destination.getTime() - (new Date().getTime())
    return countdown(distance)
  }

  handleMintError (er: any) {
    console.warn(er)
    this.error = true
  }

  @Watch('walletAddress', {deep: true})
  onChangeWalletAddress () {
    console.warn('walletAddress updated')
    this.userIsWhitelisted = false
    if (this.walletAddress) {
      this.getStorage()
    }
  }

  @Watch('activePresale')
  onChangeActivePresale () {
    console.warn('Active presale')
    if (this.activePresale) {
      this.getStorage()
    }
  }

  @Watch('activeSale')
  onChangeActiveSale () {
    console.warn('Active Sale')
    if (this.activeSale) {
      this.getStorage()
    }
  }
}










import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class TrooperzHoodImg extends Vue {
  titleClass = 'cb-neon-blue'
  
  @Prop()
  imgPath!: string

  @Prop()
  index!: string

}

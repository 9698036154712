


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Attributes, TrooperzMetadata } from '@/type';
import _ from 'lodash'

@Component
export default class MutablesAttributesInfo extends Vue {
    textDescription = 'In addition to the classic attributes stored in their metadata, Trooperz have upgradeable attributes. These evolve over time, following their actions in the game, or by using consumables that will be available in the marketplace. They allow access to more dangerous missions but also to obtain more rewards.'

    attributesConfig = [
        {
            name: 'Hacking',
            src: require('@/assets/stats/Stat_hacking.png'),
            text: `
            Specialty of Juku, it characterizes the ability of the Trooperz to use and divert the computer systems of T-City for their own interest, and often against their enemies.
Trooperz skilled in hacking are able to annihilate their enemies just by hijacking their cybernetic device or electronic equipment.
Don't trust your T-Bot if a Trooperz skilled in hacking is against you…
            `
        },
        {
            name: 'Trickery',
            src: require('@/assets/stats/Stat_trickery.png'),
            text: `
            Specialty of Aki. The Trooperz are generally simple beings, big kids who go from fun to violence depending on the events of their daily lives...
But some are smarter than others, and maybe even a little too much. They take advantage of the credulity and naivety of other Trooperz, but also of the weaknesses of the system, to enrich themselves and achieve their ends. These Trooperz naturally have a high trickery rate.`
        },
        {
            name: 'Close combat',
            src: require('@/assets/stats/Stat_close_combat.png'),
            text: `Specialty of Shibu, it determines the mastery of martial arts, the talents in street fighting of the Trooperz, but also their ability to use bladed weapons. The Trooperz who are good in close combat only need their hands to overcome their enemies, but always have at least one bladed weapon with them if the situation gets a little too bad…`
        },
        {
            name: 'Firearms',
            src: require('@/assets/stats/Stat_fire_arms.png'),
            text: `Specialty of Kabu. Do you like big guns? The explosions? Do you think the quality of a Trooperz is determined by the size of its weapons? If so, chances are your firearms level is high.`
        },
        {
            name: 'Energy',
            src: require('@/assets/stats/Stat_energy.png'),
            text: `
            Energy allows Trooperz to perform actions, such as training or going on missions. They lose energy doing this, and when they do, they no longer gain rewards. Fortunately, they can rest to recover some! Who said everything was dark in T city?`
        },
        {
            name: 'XP',
            src: require('@/assets/stats/Stat_xp.png'),
            text: `Experience allows Trooperz to level up, and get more rewards during missions. It is acquired by training vigorously and assiduously, qualities unfortunately still rare in T city.`
        }
    ]

}











import { TitleContent } from '@/type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TextAlone from '@/components/TextAlone.vue';

@Component({
  components: {TextAlone}
})
export default class InfoText extends Vue {
  titleClass = 'cb-neon-blue'

  @Prop()
  content!: TitleContent[]

  @Prop()
  addClass!: string

  get contentFiltered () {
    return this.content.filter(item => {
      return !item.hide
    })
  }

}

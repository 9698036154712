



















import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Attributes, TrooperzMetadata } from '@/type';
import _ from 'lodash'
@Component
export default class Blog extends Vue {}

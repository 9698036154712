







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
    @Prop()
    nbTrooperz!: number

    maxTrooperz = this.$config?.nbTrooperz
    
    mounted () {
        this.setNbTrooperz(this.nbTrooperz)
    }

    setNbTrooperz (nb: number) {
      let max = nb
      if (this.maxTrooperz) {
          max = this.maxTrooperz
      }
      this.setBarPerc(Math.round((nb/max) * 100))
    }

    setBarPerc(perc: number) {
       let elem = document.getElementById("bar1");
       if (elem) {
          elem.style.width = perc + "%";
       }
    }

    @Watch('nbTrooperz')    
    onChangeNbTrooperz () {
        this.setNbTrooperz(this.nbTrooperz)
    }
}


















import { Component, Prop, Vue } from 'vue-property-decorator';
import CrewCard from '@/components/CrewCard.vue';

@Component({
    components: {
        CrewCard
    }
})
export default class Crew extends Vue {
  crewConfig = [
      {
          name: 'HeyJ0',
          twitter: '@HeyJ0_',
          role: 'Artist and Co-Founder',
          img: require('../assets/trooperz/178.png')
      },
      {
          name: 'DiNow',
          twitter: '@_quentinGary',
          role: 'Software engineer and Co-Founder',
          img: require('../assets/trooperz/206.png')
      },
            {
          name: 'Relics',
          twitter: '@Relics_OG',
          role: 'Marketing & Communication',
          img: require('../assets/trooperz/299.png')
      },
            {
          name: 'Mikoo',
          twitter: '@MickaT6959',
          role: 'Universe builder',
          img: require('../assets/trooperz/371.png')
      }
  ]
}

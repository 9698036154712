







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class NeonBar extends Vue {

}

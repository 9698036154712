



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Thumbnail from '@/components/Thumbnail.vue';
import MintButton from '@/components/MintButton.vue'
import InfoText from '@/components/InfoText.vue';
import MinterComponent from '@/components/MinterComponent.vue';
import Gif from '@/components/Gif.vue'
import TrooperzHood from '@/components/TrooperzHood.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import TrooperzCarousel from '@/components/TrooperzCarousel.vue';
import UniqueTrooperz from '@/components/UniqueTrooperz.vue'
import YourTrooperz from '@/components/YourTrooperz.vue';
import MutablesAttributesInfo from '@/components/MutablesAttributesInfo.vue';
import Timeline from '@/components/Timeline.vue';
import NeonBar from '@/components/NeonBar.vue';
import Banner from '@/components/Banner.vue';
import Crew from '@/components/Crew.vue';
import Blog from '@/components/Blog.vue';
import ProductBanner from '@/components/ProductBanner.vue';
import ContractInfos from '@/components/ContractInfos.vue';
import WhyTezos from '@/components/WhyTezos.vue';
import { LIMIT_SCREEN_SMALL } from '@/constants';
import { TitleContent } from '@/type';

const LOREM =  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'


@Component({
  components: {
    Thumbnail,
    InfoText,
    Gif,
    MintButton,
    TrooperzHood,
    MinterComponent,
    ProgressBar,
    NeonBar,
    TrooperzCarousel,
    Timeline,
    YourTrooperz,
    Banner,
    Crew,
    WhyTezos,
    ProductBanner,
    UniqueTrooperz,
    Blog,
    MutablesAttributesInfo,
    ContractInfos
  },
})
export default class Home extends Vue {
  @Prop()
  windowWidth!: number
  thumbnail = false


  hoodList: Array<{title: string, content: string, img: string}> = [
    {
      title: 'Kabu',
      content: `
        Smuggler, cybernetic and arms dealer, legend has it that every deal in T-City benefits Kabu in one way or another.

Knowing your products well is the basis for being a good seller, which is why they are true masters in the handling of firearms.

      `,
      img: require('../assets/trooperz/92.png')
    },
    {
      title: 'Shibu',
      content: `
      Contract killers, mercenaries and close combat experts, the Shibu’s will be your best allies if your intention is to eliminate a target.
          They follow their code of honor to the letter. Methodical and efficient, they are real killing machines.
      `,
      img: require('../assets/trooperz/250_old.png')
    },
    {
      title: 'Aki',
      content: `
        Scammers and con artists at the service of the Corpos, always in the wrong places. Theft and scam are their specialties, they never hesitate to trade their honor for money.
They took control of the Aki neighborhood after a deal with T-Corp in 3007, bizarrely right after the murder of the entire board of directors of Satoni Tech.
      `,
      img: require('../assets/trooperz/65_old.png')
    },
    {
      title: 'Juku',
      content: `
      Cryptography experts and always ready to get a new cybernetic prosthesis, it is said that they were responsible for the hack that ended the Global Web in 3005.
No one really knows what their goals are, but we know they always take pleasure in putting obstacles in the way of the Corpos.

      `,
      img: require('../assets/trooperz/266.png')
    }
  ]

  contentInfoText: TitleContent[] = [
    {
      title: 'What is Trooperz?',
      content: `
        1 City, 4 Districts, 3201 Trooperz are coming with more than 20 attributes and 400 elements.

        Trooperz are a generative collection of NFTs. Each of the Trooperz has unique, randomly generated characters, each with a rarity rate. The Trooperz will come in the form of an FA2 Token on the Tezos blockchain.
      `,
      img: require('../assets/i_web/TrooperzDataSheet.png'),
      subtitle: 'Trooperz are coming!'
    },
    {
      title: 'What can i do with my Trooperz?',
      content: `
        Trooperz NFTs are central to the entire Trooperz universe, and their possession makes you a member of the Trooperz Club and will provide you with access to benefits and exclusives throughout your adventure in this universe.
        The Trooperz will be at the center of an original, exciting and playful Cyberpunk universe, and give their owners the title of member of the Trooperz Club.
      `,
      img: require('../assets/i_web/Propaganda.png'),
      subtitle: 'Join the club!'
    },
    {
      title: 'Where can i trade my Trooperz?',
      content: LOREM,
      hide: true
    },
    {
      title: 'How work the distribution system?',
      content: `
        In order to ensure a fair distribution of trooperz, the contract contains a provenance hash so that minters can verify that trooperz are not assigned after reveal. In addition, the start id is chosen randomly.
      `,
      hide: true
    },
    {
      title: 'Roadmap',
      content: `
        We aim to create an original, exciting and playful Cyberpunk universe, centered around the Trooperz and the Trooperz Club.
        Our ultimate goal is to offer collectors different ways to interact with their Trooperz, across different media, through art and game.
        
        Trooperz will be at the center of an Art And Play system, where you send your Trooperz on missions, and earn rewards that allow you to collect other NFTs.
        We have the ambition to create a long term project and to develop the Trooperz universe as far as we can ! 
      `,
      img: require('../assets/i_web/TROOPERZCLUB.png'),
      subtitle: 'Art, Collect, and Play'
    },
    {
      title: 'Manifesto !',
      content: `
        Welcome to T-City, a dystopian universe but also a caricature of a world filled with excess.

The Trooperz are the main actors of this original, exciting and playful Cyberpunk universe. Owning a Trooperz will make you a member of the Trooperz Club.
We want to offer our collectors different ways to interact with their Trooperz obviously on social media but also through art and games.
We will develop a healthy community, organically, which will slowly take control of the Trooperz universe.

In our mind, the Trooperz are not only another PFPs NFTs project. It's a universe in which NFTs and Tezos blockchain gave us the opportunity to create.
That’s why we truly believe in the power of NFTs and Tezos.
Made by NFTs Loverz, for NFTs Loverz, we also want to create a new space for Tezos artists to share their work, through our discord and our Trooperz Club future marketplace.

Join the adventure by collecting Trooperz NFTs and become an exclusive member of the Trooperz Club.
Join our universe and become actors on this project.
We are all Trooperz !
      `,
      subtitle: 'Welcome to T-City'
    },
  ]

  infoTextTCity: TitleContent[] = [
        {
      title: 'T-City',
      content: `
        T-City is a cybernetic and dark city, plagued by injustice and corruption. Here, corporations rule the nations (around the world), and the line between good and bad guys is thin.
4 gangs dominate T-City : Kabu, Aki, Shibu & Juku. Their members are called the Trooperz. Masters of martial arts and experts in cybernetic technologies, the Trooperz defend the interests of their gang, at the risk of their lives.

      `,
      img: require('../assets/TCITY MAP.png')
    },
  ]

  getNbMint () {
    return 2132
  }

  get contentInfoTextNoHide () {
    return this.contentInfoText.filter(item => !item.hide)
  }

  get displayListTrooperz () {
    return this.windowWidth > LIMIT_SCREEN_SMALL
  }

  get nbDisplay () {
    return this.windowWidth > LIMIT_SCREEN_SMALL ? 9 : 6
  }

  get carouselSize () {
    return this.windowWidth <= LIMIT_SCREEN_SMALL ? 260 : 360
  }
}










import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class TrooperzHoodText extends Vue {
  titleClass = 'cb-neon-blue'

  @Prop()
  content!: string

  @Prop()
  index!: string

}

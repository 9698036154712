





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {

    }
})
export default class UniqueTrooperz extends Vue {
  @Prop()
  content!: string

  textDescription = `
        Discover 6 unique Trooperz in the collection! 
        They are the main characters of the Trooperz universe.
  `

  uniqueConfig = [
      {
          name: 'Zero',
          src: require('../assets/trooperz_unique/Boss_aki.png'),
          text: `
            Zero, leader of the Aki, is the most unstable of the 4 gang leaders he watches with jealousy.

            He is feared for his unpredictable side.

            Strategist and manipulator: he is also gifted in science and specializes in genetic manipulation.
          `
      },
      {
          name: 'Jugo',
          src: require('../assets/trooperz_unique/Boss_juku.png'),
          text: `
            Jugo: leader of the Jukus, he is calm and thoughtful but also a 'calculating' individual.

            He strives to organize  large-scale cyber attacks to shake up the established order.

            Jugo seeks above all the prosperity of its peers...
          `
      },
      {
          name: 'Matsuri',
          src: require('../assets/trooperz_unique/Boss_kabu.png'),
          text: `
            Matsuri, the legendary leader of T-City's thieves...

            He doesn't hesitate to commit all kinds of baseness to achieve his ends.
            His ruthlessness allowed him to establish himself as the leader of the the Kabu's gang.
          `
      },
      {
          name: 'Matsumoto',
          src: require('../assets/trooperz_unique/Boss_shibu.png'),
          text: `
            Matsumoto: he is the leader of the Shibu's gang composed of T-City's most fearsome assassins.

            Known for his impulsiveness and violence, he nonetheless strives to follow the old Yakuza rules and puts honor above all else. 

          `
      },
      {
          name: 'Jun',
          src: require('../assets/trooperz_unique/Jun.png'),
          text: `
            Meet Jun 順, obedience in Japanese.

            Despite his young age, this member of Shibu quickly drawn Matsumoto's attention with his many skills, untill making him his protege.
            What is certain is that Jun has something special.

            By the way Jun will be at the heart of the comics.
          `
      },
      {
          name: 'Adam',
          src: require('../assets/trooperz_unique/Adam.png'),
          text: `
            Adam is the leader of the T-CORP. He controls and manipulates T-City.
            We don't know much about him, he has a very discreet personality, operating in the shadows...
          `
      }
  ]
}
